
import { isMobile } from '@/utils/utils'
import eventBus from '@/utils/event-bus'
import { getSecondNodes } from '@/api/policy'

const cmsNewsUrl = process.env.CMS_NEWS_URL
export default {
  name : 'HomeCompany01',
  layout : 'base',
  scrollToTop : true,
  props : {
    config : Array,
    data : Object
  },
  data() {
    return {
      cmsNewsUrl,
      newsIcon : require( 'assets/images/indexHome/1.png' ),
      qaIcon : require( 'assets/images/indexHome/2.png' ),
      nodesIcon : require( 'assets/images/indexHome/3.png' ),
      btn_re : require( 'assets/images/indexHome/btn_re.png' ),
      tIcon : require( 'assets/images/indexHome/policy3.png' ),
      qa1Icon : require( 'assets/images/indexHome/icon_admin.png' ),
      qa2Icon : require( 'assets/images/indexHome/qa.png' ),
      text : '',
      tatimer : null,
      openAddLink : false,
      showOpenMap : false,
      addrInps : 2,
      classOption : {
        step : 1,
        limitMoveNum : 8,
        hoverStop : true,
        direction : 1
      },
      secondNodes : []
    }
  },
  computed : {
    newsData : function() {
      return this.data.newsData
    },
    questionList : function() {
      return this.data.questionList
    },
    companyCss : function() {
      const companyCss = {}
      this.config.forEach( i => {
        if ( i.keyName === 'topImg' ) {
          companyCss.topBg = i.value
        } else if ( i.keyName === 'companyName' ) {
          companyCss.companyName = i.value
        } else if ( i.keyName === 'prefix' ) {
          companyCss.prefix = i.value
        } else if ( i.keyName === 'info' ) {
          companyCss.info = i.value
        } else if ( i.keyName === 'companyImg' ) {
          companyCss.companyImg = i.value
        }
      } )
      return companyCss
    }
  },
  mounted() {
    const _this = this
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua && !this.$route.query.pc ) {
        window.location.href = `/redirect/mobile`
      }
      const scrollElem = this.$refs.tableBox
      document.addEventListener( 'visibilitychange', function() {
        // 用户离开了当前页面
        if ( document.visibilityState === 'hidden' ) {
          // const scrollElem = this.$refs.tableBox
          scrollElem.scrollTo( 0, 0 )
          clearInterval( _this.tatimer )
          console.log( 888888888888888 )
        }
        // 用户打开或回到页面
        if ( document.visibilityState === 'visible' ) {
          // scrollElem.scrollTo(0, 0)
          _this.handleScrollBottom()
        }
      } )
    }
    this.qrCreateList = JSON.parse( JSON.stringify( this.$store.getters.qrList ) )
    eventBus.$on( 'loginOver', k => {
      if ( _this.$store.getters.token ) {
        _this.closedLogin()
      }
    } )
    this.handleScrollBottom()

    getSecondNodes().then( res => {
      res.rows.map( i => {
        i.createTime = i.createTime.split( ' ' )[0]
        i.companyNameText = i.companyName.substr( 0, 3 ) + '...'
        i.industryText = i.industry.substr( 0, 3 ) + '...'
        if ( i.website ) {
          if ( i.website.indexOf( 'http' ) < 0 ) {
            i.website = `http://${i.website}`
          }
        }
      } )
      this.secondNodes = res?.rows || []
    } )
  },
  beforeDestroy() {
    clearInterval( this.tatimer )
  },
  methods : {
    openLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    closedLogin() {
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        if ( this.qrCreateList.length > 0 ) {
          setTimeout( () => {
            this.saveQrToUser()
          }, 800 )
        }
      }
    },
    handleScrollBottom( s ) {
      const that = this
      const scrollElem = this.$refs.tableBox
      let t = s || 0
      if ( scrollElem ) {
        // if (t < scrollElem.scrollHeight) {
        this.tatimer = setInterval( () => {
          t = t + 5
          scrollElem.scrollTo( { top : t, behavior : 'smooth' } )
          if ( scrollElem.scrollHeight == ( scrollElem.scrollTop + 320 ) ) {
            scrollElem.scrollTo( {
              top : 0,
              behavior : 'auto'
            } )
            t = 0
          }
        }, 100 )
        // }
        scrollElem.onmouseover = function() {
          clearInterval( that.tatimer )
        }
        scrollElem.onmouseout = function() {
          that.handleScrollBottom( scrollElem.scrollTop )
        }
      }
    },
    goToMore( v ) {
      if ( v === 1 ) {
        window.open( process.env.CMS_NEWS_URL )
      } else if ( v === 2 ) {
        eventBus.$emit( 'activeIndex', '6' )
        this.$router.push( { path : '/community' } )
      }
    },
    goToComm() {
      eventBus.$emit( 'activeIndex', '6' )
    },
    toBigScreen() {
      this.$router.push( { path : '/map' } )
    },
    toBigScreen1() {
      this.$router.push( { path : '/scanMap' } )
    },
    toPlainText( html ) {
      return html?.replace( /<[^>]*>/g, '' ).replace( /[\r\n\s]/g, '' ).trim()
    }
  }
}

