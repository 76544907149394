
import {
  getArticleList,
  // getArticleInfo,
  addComment,
  getComment,
  openListAnswer,
  getCommentList,
  postComment,
  getCommentSubList
} from '@/api/answer'
import {
  listCaseList
  // caseDetail
} from '@/api/industry'
import {
  listPolicyList
  // policyDetail
} from '@/api/policy'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import moment from 'moment'
import eventBus from '@/utils/event-bus'
const cmsNewsUrl = process.env.CMS_NEWS_URL

export default {
  name : 'CommunityAsk',
  components : {},
  layout : 'base',
  scrollToTop : true,
  async asyncData( context ) {
    let searchData = null
    let pagesTotal = null
    try {
      const p = {
        pageNum : 1,
        pageSize : 10,
        title : context.query.value
      }
      const res = await openListAnswer( p )
      pagesTotal = ( res.total / 10 ).toString()
      if ( pagesTotal.indexOf( '.' ) > -1 ) {
        pagesTotal = parseInt( pagesTotal ) + 1
      }
      res.rows.map( i => {
        if ( parseInt( i.createBy ) ) {
          i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
        } else {
          i.nickName = i.createBy
        }
      } )
      searchData = res.rows
    } catch {
      // ignore error
    }
    return { searchData, pagesTotal }
  },
  data() {
    return {
      loading : false,
      registerOpen : false,
      loginOpen : false,
      searchValue : '',
      defaultImg : require( '@/assets/images/default.png' ),
      searchIcon : require( '@/assets/images/searchIcon.png' ),
      iconc : require( '@/assets/images/community/icon_2.png' ),
      toTop : require( '@/assets/images/toTop.png' ),
      homeIcon : require( '@/assets/images/icon_home.png' ),
      sjy : require( '@/assets/images/community/sjy.png' ),
      typeList : [
        {
          name : '社区'
        },
        {
          name : '经验文章'
        },
        {
          name : '行业案例'
        },
        {
          name : '产品模板'
        },
        {
          name : '资源中心'
        }
      ],
      actName : '社区',
      pageNum : 1,
      pagesTotal : 1,
      searchData : [],
      scrollTop : 0,
      replyTo : null,
      details : null,
      answerValue : '',
      answerValueT : '',
      pageComNum : 1,
      pagesCTotal : 1,
      commentData : [],
      coPageNum : 1,
      coTotalPages : 1,
      user : null,
      showCom : false,
      from : null
    }
  },
  head() {
    return {
      title : `社区问答`
    }
  },

  computed : {},

  created() {

  },
  mounted() {
    // if (this.$route.query.from == 'front') {
    //   this.actName = '经验文章'
    //   this.from = 'front'
    //   this.getDetails({ id: this.$route.query.id })
    // }
    this.searchValue = this.$route.query.value
    this.search()
    // window.addEventListener('scroll', this.handleScroll, true)
    if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
      this.user = {
        name : this.$store.getters.name
      }
    }
    eventBus.$emit( 'getPageData', true )
  },
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll)
  },
  methods : {
    openLogin() {
      this.loginOpen = true
    },
    openRegister() {
      this.registerOpen = true
    },
    closedRegister() {
      this.registerOpen = false
    },
    closedLogin() {
      this.loginOpen = false
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        window.location.reload()
      }
    },
    // handleScroll() {
    //   this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    // },
    goToTop() {
      window.scrollTo( {
        left : 0,
        top : 0,
        behavior : 'smooth'
      } )
    },
    back() {
      if ( this.from == 'front' ) {
        this.$router.push( { path : '/community', params : { tab : 2 }} )
      }
      this.details = null
      this.commentData = []
      this.pageComNum = 1
      this.pagesCTotal = 1
    },
    search() {
      this.loading = true
      this.pageNum = 1
      this.pagesTotal = 1
      this.searchData = []
      if ( this.actName == '社区' ) {
        this.openListAnswer()
      } else if ( this.actName == '经验文章' ) {
        this.getArticleList()
      } else if ( this.actName == '行业案例' ) {
        this.getCaseList()
      } else if ( this.actName == '资源中心' ) {
        this.getPolicyList()
      }
    },
    selectType( v ) {
      this.actName = v
      this.pageNum = 1
      this.pagesTotal = 1
      this.searchData = []
      this.commentData = []
      this.pageComNum = 1
      this.pagesCTotal = 1
      this.loading = true
      if ( v == '社区' ) {
        this.openListAnswer()
      } else if ( v == '经验文章' ) {
        this.getArticleList()
      } else if ( v == '行业案例' ) {
        this.getCaseList()
      } else if ( v == '产品模板' ) {
        this.$router.push( { path : '/templateLibrary', query : { value : this.searchValue }} )
        eventBus.$emit( 'menuBus', 3 )
      } else if ( v == '资源中心' ) {
        window.location = cmsNewsUrl + '/article/news/list?keyword=' + this.searchValue
      }
    },
    openListAnswer() {
      const p = {
        pageNum : 1,
        pageSize : 10,
        title : this.searchValue
      }
      openListAnswer( p ).then( res => {
        if ( this.pageNum <= this.pagesTotal ) {
          this.pagesTotal = ( res.total / 10 ).toString()
          if ( this.pagesTotal.indexOf( '.' ) > -1 ) {
            this.pagesTotal = parseInt( this.pagesTotal ) + 1
          }
          if ( res.rows.length > 0 ) {
            res.rows.map( i => {
              if ( parseInt( i.createBy ) ) {
                i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
              } else {
                i.nickName = i.createBy
              }
            } )
            this.searchData = this.searchData.concat( res.rows )
          } else {
            this.searchData = []
          }
        }
        this.loading = false
      } )
    },
    getArticleList() {
      const p = {
        pageNum : this.pageNum,
        pageSize : 10,
        query : this.searchValue
      }
      getArticleList( p ).then( res => {
        if ( this.pageNum <= this.pagesTotal ) {
          this.pagesTotal = ( res.total / 10 ).toString()
          if ( this.pagesTotal.indexOf( '.' ) > -1 ) {
            this.pagesTotal = parseInt( this.pagesTotal ) + 1
          }
          if ( res.rows.length > 0 ) {
            res.rows.map( i => {
              if ( parseInt( i.createUserName ) ) {
                i.createUserName = i.createUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
              }
            } )
            this.searchData = this.searchData.concat( res.rows )
          } else {
            this.searchData = []
          }
        }
        this.loading = false
      } )
    },
    getCaseList() {
      this.loading = true
      const p = {
        pageNum : this.pageNum,
        pageSize : 10,
        title : this.searchValue
      }
      listCaseList( p ).then( res => {
        if ( this.pageNum <= this.pagesTotal ) {
          this.pagesTotal = ( res.total / 10 ).toString()
          if ( this.pagesTotal.indexOf( '.' ) > -1 ) {
            this.pagesTotal = parseInt( this.pagesTotal ) + 1
          }
          if ( res.rows.length > 0 ) {
            this.searchData = this.searchData.concat( res.rows )
          } else {
            this.searchData = []
          }
        }
        this.loading = false
      } )
    },
    getPolicyList() {
      const p = {
        pageNum : this.pageNum,
        pageSize : 10,
        query : this.searchValue
      }
      listPolicyList( p ).then( res => {
        if ( this.pageNum <= this.pagesTotal ) {
          this.pagesTotal = ( res.total / 10 ).toString()
          if ( this.pagesTotal.indexOf( '.' ) > -1 ) {
            this.pagesTotal = parseInt( this.pagesTotal ) + 1
          }
          if ( res.rows.length > 0 ) {
            res.rows.map( i => {
              i.time = i.time ? moment( i.time ).format( 'YYYY-MM-DD' ) : ''
              i.title = i.title.replace( /<em>/g, '' )
              i.title = i.title.replace( /<\/em>/g, '' )
              i.content = i.content.replace( /<em>/g, '' )
              i.content = i.content.replace( /<\/em>/g, '' )
              i.content = i.content.replace( /&nbsp;/g, '' )
            } )
            this.searchData = this.searchData.concat( res.rows )
          } else {
            this.searchData = []
          }
        }
        this.loading = false
      } )
    },
    loadMore() {
      this.pageNum = this.pageNum + 1
      if ( this.actName == '社区' ) {
        this.openListAnswer()
      } else if ( this.actName == '经验文章' ) {
        this.getArticleList()
      } else if ( this.actName == '行业案例' ) {
        this.getCaseList()
      } else if ( this.actName == '资源中心' ) {
        this.getPolicyList()
      }
    },
    moreCom() {
      this.pageComNum = this.pageComNum + 1
      this.getComment()
    },
    getDetails( v ) {
      this.loading = true
      if ( this.actName == '社区' ) {
        // this.details = v
        // this.coPageNum = 1
        // this.coTotalPages = 1
        // this.getCommentList()
        // setTimeout(() => {
        //   this.loading = false
        // }, 500)
        this.$router.push( { path : '/community/answerDetail', query : { id : v.id }} )
      } else if ( this.actName == '经验文章' ) {
        // this.getArticleData(v.id)
        this.$router.push( { path : '/community/articleDetail', query : { id : v.id }} )
      } else if ( this.actName == '行业案例' ) {
        // this.getCaseData(v.id)
        this.$router.push( { path : '/industryCase/details', query : { id : v.id, industry : v.industry }} )
        eventBus.$emit( 'menuBus', 7 )
      }
      // else if (this.actName == '资源中心') {
      //   this.getPolicyData(v.id)
      // }
      this.goToTop()
    },
    // getArticleData(v) {
    //   this.commentData = []
    //   this.coPageNum = 1
    //   this.coTotalPages = 1
    //   getArticleInfo({ id: v }).then(res => {
    //     res.data.content = res.data.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
    //     res.data.content = res.data.content.replace(/<iframe/g, "<iframe style='width:100%;height:500px;'");
    //     this.details = res.data
    //     this.getComment()
    //     this.loading = false
    //   })
    // },
    // getCaseData(v) {
    //   caseDetail(parseInt(v)).then(res => {
    //     res.data.content = res.data.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
    //     res.data.content = res.data.content.replace(/<iframe/g, "<iframe style='width:100%;height:500px;'");
    //     this.details = res.data
    //     this.loading = false
    //   })
    // },
    // getPolicyData(v) {
    //   policyDetail({ id: v }).then(res => {
    //     res.time = res.time ? res.time.split('T')[0] : ''
    //     res.richContent = res.richContent.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
    //     res.richContent = res.richContent.replace(/<iframe/g, "<iframe style='width:100%;height:500px;'");
    //     this.details = res
    //     this.loading = false
    //   })
    // },
    // 社区评论
    getCommentList() {
      const p = {
        answerId : this.details.id,
        pageNum : this.coPageNum,
        pageSize : 5
      }
      getCommentList( p ).then( res => {
        if ( this.coPageNum <= this.coTotalPages ) {
          this.coTotalPages = ( res.total / 5 ).toString()
          if ( this.coTotalPages.indexOf( '.' ) > -1 ) {
            this.coTotalPages = parseInt( this.coTotalPages ) + 1
          }
          res.rows.map( i => {
            i.showReply = false
            i.nickName = i.sysUser ? i.sysUser.nickName : ''
            if ( parseInt( i.createBy ) ) {
              i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            } else {
              i.nickName = i.createBy
            }
            if ( i.childrenList.length > 0 ) {
              i.childrenList.map( k => {
                if ( parseInt( k.createBy ) ) {
                  k.createBy = k.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
                }
                k.showReply = false
              } )
            }
          } )
          if ( res.rows.length > 0 ) {
            this.commentData = this.commentData.concat( res.rows )
          } else {
            this.commentData = []
          }
        }
      } )
    },
    moreComAns() {
      this.coPageNum = this.coPageNum + 1
      this.getCommentList()
    },
    subMoreCom( v ) {
      const p = {
        answerId : v.answerId,
        parentId : v.id,
        id : v.childrenList[v.childrenList.length - 1].id,
        top : 10
      }
      getCommentSubList( p ).then( res => {
        if ( res.rows.length > 0 ) {
          res.rows.map( u => {
            if ( parseInt( u.createBy ) ) {
              u.createBy = u.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            }
            u.showReply = false
            v.childrenList.push( u )
          } )
        } else {
          v.isMore = 1
        }
        this.$forceUpdate()
      } )
    },
    toAnswer() {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        this.openLogin()
      } else {
        this.showCom = true
      }
    },
    // 文章评论
    getComment() {
      // this.pageComNum = 1
      // this.pagesCTotal = 1
      const p = {
        articleId : this.details.id,
        replyTo : this.replyTo,
        pageNum : this.pageComNum,
        pageSize : 20
      }
      getComment( p ).then( res => {
        if ( this.pageComNum <= this.pagesCTotal ) {
          this.pagesCTotal = ( res.total / 20 ).toString()
          if ( this.pagesCTotal.indexOf( '.' ) > -1 ) {
            this.pagesCTotal = parseInt( this.pagesCTotal ) + 1
          }
          res.rows.map( i => {
            if ( parseInt( i.createUserName ) ) {
              i.createUserName = i.createUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            }
            if ( parseInt( i.replyToUserName ) ) {
              i.replyToUserName = i.replyToUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            }

            i.showReply = false
          } )
          this.commentData = this.commentData.concat( res.rows )
        }
      } )
    },
    answerQuestion( item ) {
      let p

      if ( !item ) {
        if ( this.answerValueT == '' || !this.answerValueT ) {
          this.$message.warning( '请填写内容！' )
          return false
        }
        p = {
          articleId : this.details.id,
          replyTo : null,
          content : this.answerValueT
        }
      } else {
        if ( this.answerValue == '' || !this.answerValue ) {
          this.$message.warning( '请填写内容！' )
          return false
        }
        p = {
          articleId : this.details.id,
          replyTo : item.id,
          content : this.answerValue
        }
      }

      addComment( p ).then( res => {
        if ( res.code == 200 ) {
          this.$message.success( '评论成功' )
          this.answerValue = ''
          this.answerValueT = ''
          this.pageComNum = 1
          this.pagesCTotal = 1
          this.commentData = []
          this.getComment()
          this.showCom = false
        }
      } )
    },
    answerQuestionSQ( t, v ) {
      let p
      if ( t == 'ansTop' ) {
        if ( this.answerValueT == '' || !this.answerValueT ) {
          this.$message.warning( '请填写内容！' )
          return false
        }
        p = {
          answerId : this.details.id,
          content : this.answerValueT
        }
        postComment( p ).then( res => {
          if ( res.code == 200 ) {
            this.$message.success( '提交成功' )
            this.answerValueT = ''
          }
        } )
      } else if ( t == 'ansItem' ) {
        if ( this.answerValue == '' || !this.answerValue ) {
          this.$message.warning( '请填写内容！' )
          return false
        }
        p = {
          answerId : this.details.id,
          content : this.answerValue,
          replyFromUser : this.user.name,
          replyToUser : v.createBy,
          parentId : v.id
        }
        postComment( p ).then( res => {
          if ( res.code == 200 ) {
            this.$message.success( '提交成功' )
            this.answerValue = ''
          }
        } )
      }
      this.commentData = []
      this.coPageNum = 1
      this.coTotalPages = 1
      setTimeout( () => {
        this.getCommentList()
      }, 500 )
    },
    toReply( t ) {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        this.openLogin()
      } else {
        t.showReply = !t.showReply
      }
    },
    toReplyA( item ) {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        this.openLogin()
      } else {
        item.showReply = !item.showReply
      }
    }
  }
}

