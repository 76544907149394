
import { getLocationForward } from '@/api/urlForward'
import { listNewsList } from '@/api/policy'
import { openListAnswer } from '@/api/answer'

async function determineTemplate( context ) {
  const isClient = process.browser
  let url
  if ( isClient ) {
    url = getFullHost()
  } else {
    const req = context.req
    const protocol = req.headers['x-forwarded-proto'] || ( req.connection.encrypted ? 'https' : 'http' )
    const host = req.headers.host
    url = protocol + '://' + host
  }

  const config = await getLocationForward( url )
  const data = config.data || config.defaultUrl || {}
  if ( data.forwardUrl && data.forwardUrl !== '' ) {
    return { forwardUrl : data.forwardUrl }
  }
  const template = data.template.code || 'default'
  return {
    template,
    config : JSON.parse( data.configParams || '{}' ),
    title : data.title,
    keywords : data.keywords,
    description : data.description
  }
}

function getFullHost() {
  const protocol = window.location.protocol // 获取协议，例如 'http:'
  const hostname = window.location.hostname // 获取主机名，例如 'example.com'
  const port = window.location.port // 获取端口号，例如 '8080'

  // 检查端口号是否是默认的HTTP或HTTPS端口
  if ( port === '80' || port === '443' || port === '' ) {
    return `${protocol}//${hostname}`
  } else {
    return `${protocol}//${hostname}:${port}`
  }
}

export default {
  name : 'Home',
  layout : 'base',
  scrollToTop : true,
  async asyncData( context ) {
    let template = 'default'
    let config = {}
    let title = '智能码 - 二维码生成器-免费二维码制作-在线二维码解决方案-在线二维码制作-二维码在线生成-二维码在线打印'
    let keywords = '二维码生成器、二维码、二维码制作、二维码生成、在线二维码、二维码解决方案、企业节点注册、语音二维码、文档二维码、名片二维码、二维码创建器、免费在线二维码生成器'
    let description = '智能码二维码是专业二维码制作服务商，提供图片文件二维码制作、文本网址二维码制作、名片电话二维码生成系统，结合标识解析二级节点技术，注册二维码领取入网证书。'
    let newsData = []
    let questionList = []
    const secondNodes = []
    try {
      const templateRet = await determineTemplate( context )
      if ( templateRet.forwardUrl ) {
        return context.redirect( templateRet.forwardUrl )
      }
      template = templateRet.template
      config = templateRet.config
      if ( templateRet.title ) {
        title = templateRet.title
      }
      if ( templateRet.keywords ) {
        keywords = templateRet.keywords
      }
      if ( templateRet.description ) {
        description = templateRet.description
      }

      // 加载新闻和问答数据
      if ( ['default', 'company01', 'company03'].indexOf( template ) > -1 ) {
        const newsRet = await listNewsList()
        newsRet.ret.forEach( i => {
          i.addTime = i.addTime.split( ' ' )[0]
        } )
        newsData = newsRet.ret || []

        const questionRet = await openListAnswer( {
          pageNum : 1,
          pageSize : template === 'company03' ? 4 : 3
        } )
        questionList = questionRet.rows
      }
    } catch ( e ) {
      console.log( 'error', e )
    }
    return {
      config,
      template,
      title,
      keywords,
      description,
      data : {
        newsData,
        questionList,
        secondNodes
      }
    }
  },
  data() {
    return {
      template : 'default',
      config : {},
      data : {}
    }
  },
  head() {
    return {
      title : this.title,
      meta : [
        { hid : 'keywords', name : 'keywords', content : this.keywords },
        { hid : 'description', name : 'description', content : this.description }
      ]
    }
  },
  computed : {
    dynamicComponent() {
      return () => import( './' + this.template + '.vue' )
    }
  }
}
