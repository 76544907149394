
import { isMobile } from '@/utils/utils'
import eventBus from '@/utils/event-bus'

export default {
  name : 'HomeCompany02',
  layout : 'base',
  scrollToTop : true,
  props : {
    config : Array,
    data : Object
  },
  data() {
    return {}
  },
  computed : {
    companyCss : function() {
      const companyCss = {}
      this.config.forEach( i => {
        if ( i.keyName === 'topBg' ) {
          companyCss.topBg = i.value
        } else if ( i.keyName === 'title' ) {
          companyCss.title = i.value
        } else if ( i.keyName === 'prefix' ) {
          companyCss.prefix = i.value
        } else if ( i.keyName === 'subTitle' ) {
          companyCss.subTitle = i.value
        } else if ( i.keyName === 'info' ) {
          companyCss.info = i.value
        } else if ( i.keyName === 'nodeTitle' ) {
          companyCss.nodeTitle = i.value
        } else if ( i.keyName === 'nodeInfo' ) {
          companyCss.nodeInfo = i.value
        } else if ( i.keyName === 'nodeImg' ) {
          companyCss.nodeImg = i.value
        }
      } )
      return companyCss
    }
  },
  mounted() {
    const _this = this
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua && !this.$route.query.pc ) {
        window.location.href = `/redirect/mobile`
      }
    }
    eventBus.$on( 'loginOver', k => {
      if ( _this.$store.getters.token ) {
        _this.closedLogin()
      }
    } )
  },
  methods : {
    toBigScreen() {
      this.$router.push( { path : '/map' } )
    }
  }
}

