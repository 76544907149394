
// import logoImg from '@/assets/logo/logo_img_Industrycode.png'
import '@/assets/styles/global.scss'
import '@/assets/styles/reset.scss'
import Vue from 'vue'
import HomeSetting from '@/othComponents/codeMainComponents/HomeSetting'
import HomeSort from '@/othComponents/codeMainComponents/HomeSort'
import HomeMsg from '@/othComponents/codeMainComponents/HomeMsg'
import HomeConfig from '@/othComponents/codeMainComponents/HomeConfig'
import CONFIG from '@/othComponents/codeTemplate/config'
import {
  addTemplateAndCode,
  // editProductTemplate,
  getDetailsByIdNoToken
  // getDetailById
} from '@/api/product'
import {
  updateTempName
} from '@/api/template'
import eventBus from '@/utils/event-bus'
let id = -1
export default {
  name : 'HomeCodeMain',
  components : {
    // 配置
    HomeSetting,
    // 排序
    HomeSort,
    // 预览
    HomeMsg,
    // 设置
    HomeConfig
  },
  layout : 'base',
  scrollToTop : true,
  data() {
    return {
      template : null,
      centerDialogVisible : false,
      // qrCodeLogoUrl : logoImg,
      imgCode : '',
      token : this.$store.getters.token,
      // loginOpen : false,
      // registerOpen : false,
      isShowAdd : false,
      tempName : '',
      tempId : '',
      type : '',
      idisCode : ''
    }
  },
  computed : {
    // isCreate() {
    //   return !!this.template.id;
    // },
  },
  created() {

  },
  mounted() {
    this.type = this.$route.query.type
    if ( this.$route.query.id ) {
      this.getTemplateDetails( this.$route.query.id )
    } else {
      this.getBlankTemp()
    }
    // if ( this.$route.query) {
    // this.openLogin()
    // }
    // this.handleScroll()
    eventBus.$emit( 'getPageData', true )
  },
  methods : {
    handleScroll() {
      if ( process.browser ) {
        window.scrollBy( 0, -100 )
      }
    },
    getTemplateDetails( id ) {
      getDetailsByIdNoToken( id ).then( ( response ) => {
        const template = response.data
        if ( template.config && typeof template.config === 'string' ) {
          template.config = JSON.parse( template.config )
        }
        const items = []
        for ( const raw of template.items ) {
          if ( raw && CONFIG[raw.type] ) {
            if ( typeof raw.value === 'string' ) {
              raw.value = JSON.parse( raw.value )
            }
            const defaultValue = CONFIG[raw.type].defaultValue
            if ( !( defaultValue instanceof Array ) ) {
              for ( const key of Object.keys( defaultValue ) ) {
                if ( raw.value[key] === undefined ) {
                  raw.value[key] = defaultValue[key]
                }
              }
            }
            items.push( raw )
          }
        }
        template.items = items
        if ( template ) {
          this.template = template
          this.$nextTick( () => {
            this.$refs.homeSetting.syncSettingInfo( { items : template.items } )
          } )
        }
      } )
    },
    getBlankTemp() {
      this.template = {
        name : '',
        config : {
          getLocation : false,
          getWeixinUserInfo : false
        },
        items : [],
        productId : null,
        submitLoading : false
      }
      for ( const key of Object.keys( CONFIG ) ) {
        const config = CONFIG[key]
        if ( config.fixedSort || config.must ) {
          this.template.items.push( this.newItem( key ) )
        }
      }
      this.template.items.sort(
        ( a, b ) =>
          ( CONFIG[a.type].fixedSort || 100 ) - ( CONFIG[b.type].fixedSort || 100 )
      )

      // this.$nextTick( () => {
      // console.log( '>>>>', this.$refs.homeSetting )
      // this.$refs.homeSetting.syncSettingInfo({ items: template.items });
      // } )
    },
    addMyTemplate() {
      this.isShowAdd = true
    },
    addMyTemplates() {
      if ( this.tempName == '' ) {
        this.$message( {
          type : 'warning',
          message : '请填写模板名称'
        } )
        return false
      }
      const p = {
        id : this.tempId,
        name : this.tempName
      }
      updateTempName( p ).then( res => {
        if ( res.code == 200 ) {
          this.$message( {
            type : 'success',
            message : '添加成功'
          } )
          this.isShowAdd = false
        }
      } )
    },

    goBack() {
      window.history.go( -1 )
    },

    jump() {
      const data = Object.assign( {}, this.template )
      data.items = []
      let sort = 10
      for ( let item of this.template.items ) {
        // let validate = CONFIG[item.type].validate;
        // if (validate) {
        //   const msg = validate(item);
        //   if (msg) {
        //     this.$message.error(`${CONFIG[item.type].title} : ${msg}`);
        //     this.selectItem({ item, i: this.template.items.indexOf(item) });
        //     return;
        //   }
        // }
        item = Object.assign( {}, item )
        item.sort = sort += 10
        item.id = item.id < 0 ? null : item.id
        data.items.push( item )
      }
      // data.submitReview = submit;
      this.$router.push( { path : '/code/codeTemplate', query : data } )
      this.$router.push( { path : '/' } )
    },

    selectItem( prop ) {
      const item = prop.item
      this.$refs.homeMsg.selectItem( item.id )
      this.$refs.homeConfig.switchingAttConf( item )
    },
    selectItem2( prop ) {
      const item = prop.item
      this.$refs.homeConfig.switchingAttConf( item )
    },
    updateItem( prop ) {
      if ( prop.flag ) {
        const item = this.newItem( prop.item.type )
        this.template.items.push( item )
        const _this = this
        Vue.nextTick( function() {
          _this.$refs.homeSort.hightItem( item )
          _this.selectItem( { item, i : _this.template.items.length - 1 } )
        } )
      } else {
        this.template.items.splice(
          this.template.items.findIndex( ( item ) => item.type === prop.item.type ),
          1
        )
      }
    },
    removeItem( prop ) {
      const idx = this.template.items.findIndex( ( item ) => item.id === prop.id )
      const item = this.template.items[idx]
      this.$refs.homeConfig.removeItem( item )
      this.template.items.splice( idx, 1 )

      // 同步数据到sort模块
      this.$refs.homeSetting.asyncItemInfo( prop )
    },
    newItem( type ) {
      return {
        id : id--,
        type : type,
        visible : true,
        sort : 0,
        value : JSON.parse( JSON.stringify( CONFIG[type].defaultValue || {} ) )
      }
    },
    openLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    // closedLogin() {
    //   this.loginOpen = false
    //   sessionStorage.setItem( 'reload', 2 )
    // },
    submitForm( formName, submit ) {
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        const data = Object.assign( {}, this.template )
        data.items = []
        let sort = 10
        for ( let item of this.template.items ) {
          if ( item.type == 'weixinUserInfo' ) {
            this.template.config.getWeixinUserInfo = true
          }
          const validate = CONFIG[item.type].validate
          if ( validate ) {
            const msg = validate( item )
            if ( msg ) {
              this.$message.error( `${CONFIG[item.type].title} : ${msg}` )
              this.selectItem( { item, i : this.template.items.indexOf( item ) } )
              return
            }
          }
          item = Object.assign( {}, item )
          item.sort = sort += 10
          item.id = item.id < 0 ? null : item.id
          data.items.push( item )
        }
        data.submitReview = submit
        data.createTime = null
        data.updateTime = null
        data.codeCount = null
        // if ( this.template.id && this.type == '' ) {
        //   this.template.submitLoading = true
        //   editProductTemplate( data )
        //     .then( ( response ) => {
        //       this.template.submitLoading = false
        //       this.$message( {
        //         type : 'success',
        //         message : '修改成功'
        //       } )
        //       // this.$router.push({ path: "/Product/SecurityTemplate" });
        //     } )
        //     .catch( () => {
        //       debugger
        //       this.template.submitLoading = false
        //     } )
        // } else {
        this.template.submitLoading = true
        addTemplateAndCode( data )
          .then( ( response ) => {
            this.$message( {
              type : 'success',
              message : '生码成功并将该模板添加到我的模板'
            } )
            this.template.submitLoading = false

            console.log( response )
            if ( response.data.code ) {
              const code = encodeURIComponent( response.data.code )
              this.imgCode = `${window.location.origin}/w/c?c=${code}`
              this.tempId = response.data.templateId
              this.idisCode = response.data.code
              this.centerDialogVisible = true
            }

            // this.$router.push({ path: "/code/template/index" });
            // this.$router.push({ path: "/code/codeList" });
          } )
          .catch( () => {
            this.template.submitLoading = false
          } )
        // }
      } else {
        this.openLogin()
      }
    },
    copyLink() {
      const url = this.imgCode // 后台接口返回的链接地址
      const inputNode = document.createElement( 'input' ) // 创建input
      inputNode.value = url // 赋值给 input 值
      document.body.appendChild( inputNode ) // 插入进去
      inputNode.select() // 选择对象
      document.execCommand( 'Copy' ) // 原生调用执行浏览器复制命令
      inputNode.className = 'oInput'
      inputNode.style.display = 'none' // 隐藏
      this.$message.success( '复制成功' )
    }
  }
  // 路由前载入数据
  //  async beforeRouteEnter(to, from, next) {
  //   let template;
  //   console.log(1431,to)
  //   const templateId = to.params.id;
  //   const token = to.params.token
  //   if (templateId) {
  //     if (token && token != '') {
  //       getDetailById(templateId).then((response) => {
  //         template = response.data;

  //         if (template.config && typeof template.config === "string") {
  //           template.config = JSON.parse(template.config);
  //         }
  //         const items = [];
  //         for (let raw of template.items) {
  //           if (raw && CONFIG[raw.type]) {
  //             if (typeof raw.value === "string") {
  //               raw.value = JSON.parse(raw.value);
  //             }
  //             const defaultValue = CONFIG[raw.type].defaultValue;
  //             if (!(defaultValue instanceof Array)) {
  //               for (let key of Object.keys(defaultValue)) {
  //                 if (raw.value[key] === undefined) {
  //                   raw.value[key] = defaultValue[key];
  //                 }
  //               }
  //             }
  //             items.push(raw);
  //           }
  //         }
  //         template.items = items;
  //         if (to.params.copy == "true") {
  //           template.id = null;
  //           template.name = template.name + " (副本)";
  //         }
  //         next((vm) => {
  //           if (template) {
  //             vm.$data.template = template;
  //             vm.$refs.homeSetting.syncSettingInfo({ items: template.items });
  //             vm.$data.type = to.params.type
  //           }
  //         });
  //       });
  //     } else {
  //       getDetailsByIdNoToken(templateId).then((response) => {
  //         template = response.data;

  //         if (template.config && typeof template.config === "string") {
  //           template.config = JSON.parse(template.config);
  //         }
  //         const items = [];
  //         for (let raw of template.items) {
  //           if (raw && CONFIG[raw.type]) {
  //             if (typeof raw.value === "string") {
  //               raw.value = JSON.parse(raw.value);
  //             }
  //             const defaultValue = CONFIG[raw.type].defaultValue;
  //             if (!(defaultValue instanceof Array)) {
  //               for (let key of Object.keys(defaultValue)) {
  //                 if (raw.value[key] === undefined) {
  //                   raw.value[key] = defaultValue[key];
  //                 }
  //               }
  //             }
  //             items.push(raw);
  //           }
  //         }
  //         template.items = items;
  //         if (to.params.copy == "true") {
  //           template.id = null;
  //           template.name = template.name + " (副本)";
  //         }
  //         next((vm) => {
  //           if (template) {
  //             vm.$data.template = template;
  //             vm.$refs.homeSetting.syncSettingInfo({ items: template.items });
  //             vm.$data.type = to.params.type
  //           }
  //         });
  //       });
  //     }

  //   } else {
  //     next((vm) => {
  //       if (template) {
  //         vm.$data.template = template;
  //         vm.$refs.homeSetting.syncSettingInfo({ items: template.items });
  //       }
  //     });
  //   }
  // }

}
