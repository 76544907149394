
import eventBus from '@/utils/event-bus'
import { queryIdentity } from '@/api/idis/idisQuery'

export default {
  name : 'IdentityQuery',
  layout : 'base',
  async asyncData( context ) {
    const searchText = context.query.code || ''
    if ( searchText ) {
      const resultTitle = searchText.trim()
      const ret = await queryIdentity( resultTitle )
      const results = ret.data
      const hasTemplate = ret.hasTemplate
      return { searchText, resultTitle, results, hasTemplate }
    }
    return { searchText }
  },
  data() {
    return {
      results : null,
      searchText : '',
      loading : false,
      resultTitle : '',
      hasTemplate : false
    }
  },
  head() {
    return {
      title : '工业互联网标识查询'
    }
  },
  mounted() {
    eventBus.$emit( 'getPageData', true )
  },
  methods : {
    async handleSearch() {
      this.loading = true
      this.resultTitle = this.searchText.trim()
      const ret = await queryIdentity( this.resultTitle )
      this.results = ret.data
      this.hasTemplate = ret.hasTemplate

      this.$router.push( {
        name : this.$route.name,
        query : {
          code : this.resultTitle
        }
      } )
      this.loading = false
    }
  }
}
