
import eventBus from '@/utils/event-bus'
export default {
  name : 'AboutUs',
  layout : 'base',
  data() {
    return {
      active : 'platform'
    }
  },
  mounted() {
    eventBus.$emit( 'getPageData', true )
  },
  methods : {
    clickTitle( active ) {
      this.active = active
      const ele = document.body.querySelector( '#' + active )
      console.log( 'scrollto', ele.offsetTop )
      window.scrollTo( {
        left : 0,
        top : ele.offsetTop - 100,
        behavior : 'smooth'
      } )
    }
  }
}
