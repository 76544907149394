
import {
  getIndustryList
} from '@/api/template'
import { listCaseList } from '@/api/industry'
import eventBus from '@/utils/event-bus'
import { isMobile } from '@/utils/utils'
export default {
  name : 'IndustryCase',
  layout : 'base',
  scrollToTop : true,
  async asyncData( context ) {
    const industry = context.query.industry || null
    let industryList = []
    let caseList = []
    try {
      industryList = ( await getIndustryList() ).rows || []
      industryList.unshift( { dictLabel : '全部', isActive : true, dictValue : null } )
      industryList.forEach( i => {
        i.isActive = i.dictValue === industry
      } )

      const caseListResp = await listCaseList( { industry, pageNum : 1, pageSize : 18 } )
      caseList = caseListResp?.rows || []
    } catch {
      // ignore error
    }
    return { industryList, caseList, industry }
  },
  data() {
    return {
      loading : false,
      defaultImg : require( '@/assets/images/default.png' ),
      toTop : require( '@/assets/images/toTop.png' ),
      homeIcon : require( '@/assets/images/icon_home.png' ),
      goBtn : require( '@/assets/images/go_btn.png' ),
      btn20 : require( '@/assets/images/b20.png' ),
      btn21 : require( '@/assets/images/b21.png' ),
      btn22 : require( '@/assets/images/b22.png' ),
      industryList : [],
      queryParams : {
        pageNum : 1,
        pageSize : 18
      },
      industry : null,
      scrollTop : 0,
      caseList : [],
      totalPage : 1,
      subTitle : ''
    }
  },
  watch : {
    $route() {
      console.log( 'route change' )
      this.$nuxt.refresh()
    }
  },
  mounted() {
    eventBus.$emit( 'activeIndex', '7' )
    eventBus.$emit( 'getPageData', true )
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua ) {
        window.location.href = `/redirect/mobile`
      }
    }
  },
  methods : {
    goToTop() {
      window.scrollTo( {
        left : 0,
        top : 0,
        behavior : 'smooth'
      } )
    },
    loadMore() {
      this.queryParams.pageNum = this.queryParams.pageNum + 1
      this.getList()
    },
    async getList() {
      this.loading = true
      const res = await listCaseList( { industry : this.industry, ...this.queryParams } )
      if ( this.queryParams.pageNum <= this.totalPage ) {
        this.totalPage = ( res.total / 18 ).toString()
        if ( this.totalPage.indexOf( '.' ) > -1 ) {
          this.totalPage = parseInt( this.totalPage ) + 1
        }
        if ( res.rows.length > 0 ) {
          this.caseList = this.caseList.concat( res.rows )
        } else {
          this.caseList = []
        }
        this.loading = false
      }
    }
  },
  head() {
    return {
      title : '行业案例'
    }
  }
}

