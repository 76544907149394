
import eventBus from '@/utils/event-bus'
import { listPrefixSamples, listSamples } from '@/api/idis/idisQuery'

export default {
  name : 'IdentityQuery',
  layout : 'base',
  data() {
    return {
      results : null,
      sampleList : null,
      searchText : '',
      loading : false,
      samples : {
        id : [
          '88.118.8/123456',
          '88.118.8/TEST'
        ],
        prefix : [
          '88.118',
          '88.254'
        ]
      }
    }
  },
  head() {
    return {
      title : '工业互联网标识查询'
    }
  },
  async mounted() {
    eventBus.$emit( 'getPageData', true )
    this.searchText = this.$route.query.code || ''
    if ( this.searchText ) {
      this.handleSearch()
    }
    const sampleRet = await listSamples()
    this.samples.id = sampleRet.data || []
    const snmsRet = await listPrefixSamples()
    this.samples.prefix = snmsRet.data || []
  },
  methods : {
    insertSample( type ) {
      this.sampleList = this.samples[type]
    },
    async handleSearch() {
      // goto result page
      this.$router.push( { path : '/identityQuery/result', query : { code : this.searchText }} )
    }
  }
}
