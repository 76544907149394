
export default {
  name: 'businessHome',
  layout: 'base',
  scrollToTop: true,
  components: {},
  data() {
    return {
      topImg: require('../../assets/images/indexHome/bbg_img.png'),
      sbImg: require('../../assets/images/indexHome/b1.png')
    };
  },


  computed: {},

  created() { },
  mounted() { },

  methods: {
    toBigScreen() {
      this.$router.push({ path: '/map' })
    },
  }
}

