
import Editor from '@/plugins/Editor'

import { addAnswer, listHistoryAnswer, getArticleList, openListAnswer } from '@/api/answer'
import { openListTopic } from '@/api/hotTopic/topic'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import eventBus from '@/utils/event-bus'
import { isMobile } from '@/utils/utils'
export default {
  name : 'Community',
  components : {
    Editor
  },
  layout : 'base',
  scrollToTop : true,
  async asyncData( context ) {
    let hotData = null
    let questionList = null
    let anTotalPages = null
    try {
      const resA = await openListTopic( { pageNum : 1, pageSize : 2 } )
      const hotDatas = resA.rows
      if ( hotDatas[0] ) {
        const resB = await openListAnswer( {
          pageNum : 1,
          pageSize : 5,
          title : hotDatas[0].hotWords
        } )
        hotDatas[0].questionList = resB.rows
      }
      if ( hotDatas[1] ) {
        const resC = await openListAnswer( {
          pageNum : 1,
          pageSize : 5,
          title : hotDatas[1].hotWords
        } )
        hotDatas[1].questionList = resC.rows
      }
      hotData = hotDatas

      const resD = await openListAnswer( { pageNum : 1, pageSize : 5 } )
      anTotalPages = ( resD.total / 5 ).toString()
      if ( anTotalPages.indexOf( '.' ) > -1 ) {
        anTotalPages = parseInt( anTotalPages ) + 1
      }
      resD.rows.map( i => {
        i.showReply = false
        if ( parseInt( i.createBy ) ) {
          i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
        } else {
          i.nickName = i.createBy
        }
      } )
      questionList = resD.rows
    } catch {
      // ignore error
    }
    return { hotData, questionList, anTotalPages }
  },
  data() {
    return {
      loginOpen : false,
      registerOpen : false,
      searchValue : '',
      scrollTop : 0,
      defaultImg : require( '@/assets/images/default.png' ),
      toTop : require( '@/assets/images/toTop.png' ),
      searchIcon : require( '@/assets/images/searchIcon.png' ),
      hotIcon : require( '@/assets/images/community/icon_1.png' ),
      iconv : require( '@/assets/images/community/icon_3.png' ),
      icona : require( '@/assets/images/community/icon_4.png' ),
      homeIcon : require( '@/assets/images/icon_home.png' ),
      iconc : require( '@/assets/images/community/icon_2.png' ),
      uploadUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=门户社区问答提问',
      hotData : [],
      qaTabVal : '1',
      pageNum : 1,
      total : 0,
      askTabVal : '1',
      dialogVisible : false,
      dialogVisibleImg : false,
      dialogImageUrl : '',
      qaForm : {
        title : '',
        describe : '',
        pic : ''
      },
      pic : [],
      typeList : [
        {
          name : '产品建议',
          value : '0',
          color : '#4E9F2F'
        },
        {
          name : '操作使用',
          value : '1',
          color : '#16537D'
        },
        {
          name : '交流讨论',
          value : '2',
          color : '#C6941A'
        },
        {
          name : 'Bug反馈',
          value : '3',
          color : '#8C8573'
        }
      ],
      typeValue : '0',
      rules : {
        title : [
          { required : true, message : '请输入问题标题', trigger : 'blur' }
        ],
        describe : [
          { required : true, message : '请输入问题描述', trigger : 'blur' }
        ]
      },
      problemList : [],
      articleList : [],
      articlePageN : 0,
      articleTotal : 1,
      articleDetail : null,
      anPageNum : 1,
      anTotalPages : 1,
      questionList : [],
      answerValue : '',
      answerValueT : '',
      answerDetail : null,
      coPageNum : 1,
      coTotalPages : 1,
      commentData : [],
      user : null,
      showCom : false,
      answerId : '',
      loadingA : false
    }
  },
  head() {
    return {
      title : `社区问答`
    }
  },

  computed : {},

  created() {
    // this.openListAnswer()
    // this.articleLoadMore()
    // if (this.$route.query.answerID) {
    //   this.answerId = this.$route.query.answerID
    //   this.toAnswerDetail()
    // }
    // if (this.$route.query.articleId) {
    //   this.toArticleInfo(this.$route.query.articleId)
    // }
  },
  mounted() {
    if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
      this.user = {
        name : this.$store.getters.name
      }
    }
    if ( process.browser ) {
      window.addEventListener( 'scroll', this.handleScroll )
    }
    this.openListTopic()
    eventBus.$emit( 'activeIndex', '6' )
    eventBus.$emit( 'getPageData', true )
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua ) {
        window.location.href = `/redirect/mobile`
      }
    }
  },
  destroyed() {
    if ( process.browser ) {
      window.removeEventListener( 'scroll', this.handleScroll )
    }
  },
  methods : {
    handleScroll() {
      if ( process.browser ) {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      }
    },
    goToTop() {
      if ( process.browser ) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        window.scrollTo( {
          left : 0,
          top : 0,
          behavior : 'smooth'
        } )
      }
    },
    openLogin() {
      this.loginOpen = true
    },
    closedLogin() {
      this.loginOpen = false
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        this.user = {
          avatar : this.$store.getters.avatar,
          name : this.$store.getters.name
        }
      }
    },
    openRegister() {
      this.registerOpen = true
    },
    closedRegister() {
      this.registerOpen = false
    },
    search() {
      this.$router.push( { path : '/community/communityAsk', query : { value : this.searchValue }} )
    },
    handleCurrentChange( val ) {
      this.pageNum = val
      this.listHistoryAnswer()
    },
    listHistoryAnswer() {
      const p = {
        pageNum : this.pageNum,
        pageSize : 10
      }
      listHistoryAnswer( p ).then( res => {
        this.problemList = res.rows
        this.total = res.total
      } )
    },
    handleClick( tab, event ) {
      if ( tab.index == 2 ) {
        this.pageNum = 1
        this.listHistoryAnswer()
      }
    },
    handleQaClick( tab ) {
      if ( tab.index == 2 ) {
        if ( this.articlePageN < this.articleTotal ) {
          this.articlePageN = 1
          this.getArticleList()
        }
      }
    },
    // 提问
    submitForm( formName ) {
      this.$refs[formName].validate( ( valid ) => {
        if ( valid ) {
          const p = {
            title : this.qaForm.title,
            description : this.qaForm.describe,
            pic : this.pic.toString(),
            type : this.typeValue
          }
          if ( p.description.length > 1024 ) {
            this.$message.error( '问题描述过长, 请减少内容' )
            return
          }
          addAnswer( p ).then( res => {
            if ( res.code == 200 ) {
              this.$message.success( '提交成功，等待审核' )
              this.openListAnswer()
              this.handleClose()
            }
          } )
        } else {
          console.log( 'error submit!!' )
          return false
        }
      } )
    },
    handleClose() {
      this.$refs.qaForm.clearValidate()
      this.dialogVisible = false
      this.qaForm = {
        title : '',
        describe : '',
        pic : ''
      }
      this.pic = []

      document.documentElement.style.overflowY = 'auto'
    },
    askAnswer() {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      } else {
        this.$refs.picUpload?.clearFiles()
        this.typeValue = ''
        this.dialogVisible = true
        document.documentElement.style.overflowY = 'hidden'
      }
    },
    beforeUpload( file ) {
      const types = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/JPG',
        'image/JPEG',
        'image/PNG'
      ]
      const isImg = types.includes( file.type )
      const isLt2M = file.size / 1024 / 1024 < 2
      if ( this.pic.length == 3 ) {
        this.$message.error( '最多上传3张图片!' )
        return false
      }
      if ( !isLt2M ) {
        this.$message.error( '上传图片大小不能超过 2MB!' )
        return false
      }
      if ( !isImg ) {
        this.$message.error( '上传图片格式错误，仅限jpg，jpeg，png格式' )
        return false
      }
      return isLt2M && isImg
    },
    handlePicSuccess( res, file ) {
      this.pic.push( res.data )
    },
    handleRemove( file, fileList ) {
      this.pic = []
      if ( fileList.length > 0 ) {
        fileList.map( i => {
          this.pic.push( i.response.data )
        } )
      }
    },
    handlePictureCardPreview( file ) {
      this.dialogImageUrl = file.url
      this.dialogVisibleImg = true
    },
    selectType( v ) {
      this.typeValue = v
    },
    getArticleList() {
      const p = {
        pageNum : this.articlePageN,
        pageSize : 5,
        query : this.searchValue
      }
      getArticleList( p ).then( res => {
        if ( this.articlePageN <= this.articleTotal ) {
          this.articleTotal = ( res.total / 5 ).toString()
          if ( this.articleTotal.indexOf( '.' ) > -1 ) {
            this.articleTotal = parseInt( this.articleTotal ) + 1
          }
          if ( res.rows.length > 0 ) {
            res.rows.map( i => {
              if ( parseInt( i.createUserName ) ) {
                i.createUserName = i.createUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
              }
            } )
            this.articleList = this.articleList.concat( res.rows )
          } else {
            this.articleList = []
          }
        }
      } )
    },
    articleLoadMore() {
      this.articlePageN = this.articlePageN + 1
      this.getArticleList()
    },
    answerLoadMore() {
      this.anPageNum = this.anPageNum + 1
      this.openListAnswer()
    },
    toArticleInfo( v ) {
      this.$router.push( { path : '/community/communityAsk', query : { id : v, from : 'front' }} )
    },
    openListTopic() {
      const p = {
        pageNum : 1,
        pageSize : 2
      }
      openListTopic( p ).then( res => {
        this.hotData = res.rows
        if ( res.rows[0] ) {
          this.openListAnswero( res.rows[0].hotWords )
        }
        if ( res.rows[1] ) {
          this.openListAnswert( res.rows[1].hotWords )
        }
      } )
    },
    openListAnswero( v ) {
      const p = {
        pageNum : 1,
        pageSize : 5,
        title : v
      }
      openListAnswer( p ).then( res => {
        this.hotData[0].questionList = res.rows
      } )
    },
    openListAnswert( v ) {
      const p = {
        pageNum : 1,
        pageSize : 5,
        title : v
      }
      openListAnswer( p ).then( res => {
        this.hotData[1].questionList = res.rows
      } )
    },
    openListAnswer() {
      const p = {
        pageNum : this.anPageNum,
        pageSize : 5
      }
      openListAnswer( p ).then( res => {
        if ( this.anPageNum <= this.anTotalPages ) {
          this.anTotalPages = ( res.total / 5 ).toString()
          if ( this.anTotalPages.indexOf( '.' ) > -1 ) {
            this.anTotalPages = parseInt( this.anTotalPages ) + 1
          }
          res.rows.map( i => {
            i.showReply = false
            if ( parseInt( i.createBy ) ) {
              i.nickName = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            } else {
              i.nickName = i.createBy
            }
          } )
          if ( res.rows.length > 0 ) {
            this.questionList = this.questionList.concat( res.rows )
          } else {
            this.questionList = []
          }
        }
      } )
    },
    toReply( v ) {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      } else {
        this.toAnswerDetail( v )
      }
    },
    toAnswerDetail( item ) {
      this.$router.push( { path : '/community/answerDetail', query : { id : item.id }} )
    },
    toArticleDetail( item ) {
      this.$router.push( { path : '/community/articleDetail', query : { id : item.id }} )
    }
  }
}

