
import { getArticleInfo, addComment, getComment, articleClick } from '@/api/answer'
import eventBus from '@/utils/event-bus'
export default {
  name : 'ArticleDetail',
  layout : 'base',
  scrollToTop : true,
  components : {},
  async asyncData( context ) {
    let articleDetail = null
    let pagesCTotal = null
    let commentData = null
    const articleId = context.query.id
    try {
      const resA = await getArticleInfo( { id : articleId } )
      resA.data.content = resA.data.content.replace( /<img/g, "<img style='max-width:100%;height:auto;'" )
      resA.data.content = resA.data.content.replace( /<iframe/g, "<iframe style='width:100%;height:500px;'" )
      articleDetail = resA.data

      const p = {
        articleId : articleId,
        pageNum : 1,
        pageSize : 20
      }
      const resB = await getComment( p )
      pagesCTotal = ( resB.total / 20 ).toString()
      if ( pagesCTotal.indexOf( '.' ) > -1 ) {
        pagesCTotal = parseInt( pagesCTotal ) + 1
      }
      resB.rows.map( i => {
        if ( parseInt( i.createUserName ) ) {
          i.createUserName = i.createUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
        }
        if ( parseInt( i.replyToUserName ) ) {
          i.replyToUserName = i.replyToUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
        }

        i.showReply = false
      } )
      commentData = resB.rows
    } catch {
      // ignore error
    }
    return { articleId, articleDetail, commentData, pagesCTotal }
  },
  data() {
    return {
      scrollTop : 0,
      articleId : null,
      loginOpen : false,
      registerOpen : false,
      homeIcon : require( '@/assets/images/icon_home.png' ),
      toTop : require( '@/assets/images/toTop.png' ),
      iconc : require( '@/assets/images/community/icon_2.png' ),
      sjy : require( '@/assets/images/community/sjy.png' ),
      articleDetail : null,
      pageComNum : 1,
      pagesCTotal : 1,
      commentData : [],
      replyTo : null,
      answerValue : '',
      showCom : false,
      answerValueT : '',
      commitLoading : false
    }
  },

  computed : {},

  created() { },
  mounted() {
    this.articleId = this.$route.query.id
    eventBus.$emit( 'getPageData', true )
    articleClick( { id : this.articleId } )
  },
  methods : {
    moreCom() {
      this.pageComNum = this.pageComNum + 1
      this.getComment()
    },
    // 文章评论
    getComment() {
      const p = {
        articleId : this.articleId,
        replyTo : this.replyTo,
        pageNum : this.pageComNum,
        pageSize : 20
      }
      getComment( p ).then( res => {
        if ( this.pageComNum <= this.pagesCTotal ) {
          this.pagesCTotal = ( res.total / 20 ).toString()
          if ( this.pagesCTotal.indexOf( '.' ) > -1 ) {
            this.pagesCTotal = parseInt( this.pagesCTotal ) + 1
          }
          res.rows.map( i => {
            if ( parseInt( i.createUserName ) ) {
              i.createUserName = i.createUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            }
            if ( parseInt( i.replyToUserName ) ) {
              i.replyToUserName = i.replyToUserName.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
            }

            i.showReply = false
          } )
          this.commentData = this.commentData.concat( res.rows )
          this.commitLoading = false
        }
      } )
    },
    toReply( t ) {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      } else {
        t.showReply = !t.showReply
      }
    },
    toAnswer() {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      } else {
        this.showCom = true
      }
    },
    answerQuestion( item ) {
      this.commitLoading = true
      let p
      if ( !item ) {
        if ( this.answerValueT == '' || !this.answerValueT ) {
          this.$message.warning( '请填写内容！' )
          this.commitLoading = false
          return false
        }
        p = {
          articleId : this.articleId,
          replyTo : null,
          content : this.answerValueT
        }
      } else {
        if ( this.answerValue == '' || !this.answerValue ) {
          this.$message.warning( '请填写内容！' )
          this.commitLoading = false
          return false
        }
        p = {
          articleId : this.articleId,
          replyTo : item.id,
          content : this.answerValue
        }
      }

      addComment( p ).then( res => {
        if ( res.code == 200 ) {
          this.$message.success( '评论成功' )
          this.answerValue = ''
          this.answerValueT = ''
          this.pageComNum = 1
          this.pagesCTotal = 1
          this.commentData = []
          this.getComment()
          this.showCom = false
        }
      } )
    },
    closedLogin() {
      this.loginOpen = false
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        this.user = {
          name : this.$store.getters.name
        }
      }
    },
    openRegister() {
      this.registerOpen = true
    },
    closedRegister() {
      this.registerOpen = false
    },
    goToTop() {
      if ( process.browser ) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        window.scrollTo( {
          left : 0,
          top : 0,
          behavior : 'smooth'
        } )
      }
    },
    back() {
      this.$nextTick( () => {
        this.$router.push( '/community' )
      } )
    }
  },
  head() {
    return {
      title : `社区-经验文章-${this.articleDetail.title}`
    }
  }
}

